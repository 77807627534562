<template>
  <div class="modal-wrapper">
    <div class="modal-overlay" @click="closeModal"></div>

    <section
      class="assign-expert-modal modal card shadow"
      role="dialog"
      aria-labelledby="assignExpertModalTitle"
    >
      <header class="flex-row flex-align-spaced flex-center-v flex-nowrap">
        <h2 id="assignExpertModalTitle">Affecter la question à un expert</h2>
        <button class="button--icon" @click="closeModal">
          <svg
            role="img"
            class="icon"
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="14"
            fill="none"
            viewBox="0 0 14 14"
          >
            <title>Fermer</title>
            <path
              fill="currentColor"
              fill-rule="evenodd"
              d="M13.7071 0.292893C14.0976 0.683417 14.0976 1.31658 13.7071 1.70711L1.70711 13.7071C1.31658 14.0976 0.683417 14.0976 0.292893 13.7071C-0.0976311 13.3166 -0.0976311 12.6834 0.292893 12.2929L12.2929 0.292893C12.6834 -0.0976311 13.3166 -0.0976311 13.7071 0.292893Z"
              clip-rule="evenodd"
            />
            <path
              fill="currentColor"
              fill-rule="evenodd"
              d="M0.292893 0.292893C0.683417 -0.0976311 1.31658 -0.0976311 1.70711 0.292893L13.7071 12.2929C14.0976 12.6834 14.0976 13.3166 13.7071 13.7071C13.3166 14.0976 12.6834 14.0976 12.2929 13.7071L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683417 0.292893 0.292893Z"
              clip-rule="evenodd"
            />
          </svg>
        </button>
      </header>

      <div class="users-filter">
        <div class="input-group">
          <label for="users-filter">Rechercher un expert&nbsp;:</label>
          <input type="text" name="users-filter" v-model="filter.value" />
        </div>
      </div>

      <form>
        <ul role="list" class="user-list" v-if="users">
          <li v-for="user in filteredList" :key="user.id">
            <user-avatar :user="user" />

            <span
              class="tag"
              v-if="user.questions_assigned.length"
              :title="assignedQuestionsDetails(user)"
              >{{ user.questions_assigned.length }}
              {{ user.questions_assigned.length | pluralize("question") }}</span
            >

            <div class="assigned" v-if="isQuestionAssigned(user)">
              <svg
                role="img"
                class="icon"
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="13"
                fill="none"
                viewBox="0 0 18 13"
              >
                <path
                  fill="currentColor"
                  fill-rule="evenodd"
                  d="M17.7071 0.292893C18.0976 0.683417 18.0976 1.31658 17.7071 1.70711L6.70711 12.7071C6.31658 13.0976 5.68342 13.0976 5.29289 12.7071L0.292893 7.70711C-0.0976311 7.31658 -0.0976311 6.68342 0.292893 6.29289C0.683417 5.90237 1.31658 5.90237 1.70711 6.29289L6 10.5858L16.2929 0.292893C16.6834 -0.0976311 17.3166 -0.0976311 17.7071 0.292893Z"
                  clip-rule="evenodd"
                />
              </svg>
              <span class="caption">Affecté</span>
            </div>

            <button
              v-else
              class="button--primary"
              type="button"
              @click.prevent="handleClick(user)"
            >
              <span class="caption">Affecter</span>
              <svg
                class="icon"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="16"
                fill="none"
                viewBox="0 0 20 16"
              >
                <path
                  fill="currentColor"
                  fill-rule="evenodd"
                  d="M1.17157 10.7716C1.92172 10.0214 2.93913 9.6 4 9.6H9.6C10.6609 9.6 11.6783 10.0214 12.4284 10.7716 13.1786 11.5217 13.6 12.5391 13.6 13.6V15.2C13.6 15.6418 13.2418 16 12.8 16 12.3582 16 12 15.6418 12 15.2V13.6C12 12.9635 11.7471 12.353 11.2971 11.9029 10.847 11.4529 10.2365 11.2 9.6 11.2H4C3.36348 11.2 2.75303 11.4529 2.30294 11.9029 1.85286 12.353 1.6 12.9635 1.6 13.6V15.2C1.6 15.6418 1.24183 16 .8 16 .358172 16 0 15.6418 0 15.2V13.6C0 12.5391.421428 11.5217 1.17157 10.7716zM6.8 1.6C5.47452 1.6 4.4 2.67452 4.4 4 4.4 5.32548 5.47452 6.4 6.8 6.4 8.12548 6.4 9.2 5.32548 9.2 4 9.2 2.67452 8.12548 1.6 6.8 1.6zM2.8 4C2.8 1.79086 4.59086 0 6.8 0 9.00914 0 10.8 1.79086 10.8 4 10.8 6.20914 9.00914 8 6.8 8 4.59086 8 2.8 6.20914 2.8 4zM16 4C16.4418 4 16.8 4.35817 16.8 4.8V9.6C16.8 10.0418 16.4418 10.4 16 10.4 15.5582 10.4 15.2 10.0418 15.2 9.6V4.8C15.2 4.35817 15.5582 4 16 4z"
                  clip-rule="evenodd"
                />
                <path
                  fill="currentColor"
                  fill-rule="evenodd"
                  d="M12.8 7.2C12.8 6.75817 13.1582 6.4 13.6 6.4H18.4C18.8418 6.4 19.2 6.75817 19.2 7.2C19.2 7.64183 18.8418 8 18.4 8H13.6C13.1582 8 12.8 7.64183 12.8 7.2Z"
                  clip-rule="evenodd"
                />
              </svg>
            </button>
          </li>
        </ul>
      </form>
    </section>
  </div>
</template>

<style lang="scss" scoped>
@import "~@/scss/02-tools/tools.mixins";
@import "~@/scss/06-components/components.modal";

section > * + * {
  margin-top: 1rem;
}

.assign-expert-modal {
  width: 40rem;
  max-width: 100%;
}

.user-list {
  max-height: 24rem;
  overflow: scroll;

  li {
    display: flex;
    flex-flow: row wrap;
    align-content: center;
    align-items: center;
    justify-content: space-between;

    & + li {
      margin-top: 1rem;
    }

    & > * + * {
      margin-left: 1rem;
    }
  }
}

@media (max-width: 460px) {
  .user-list {
    max-height: 100%;
    overflow: initial;
    padding-bottom: 1rem;

    li {
      justify-content: stretch;

      .tag {
        display: none;
      }
      .button--primary {
        flex-basis: 100%;
      }

      & > * + * {
        margin: 1rem 0 0;
      }

      & + li {
        margin-top: 2rem;
      }
    }
  }
}

.user-card {
  flex-grow: 1;
}

.assigned {
  color: #068200;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;

  .icon {
    height: auto;
    width: 0.875em;
    flex-basis: auto;
    margin-right: 0.5em;
  }
}

.assigned,
.button--primary {
  flex-basis: 8rem;
  justify-content: center;
}
</style>

<script>
// User Avatar
import UserAvatar from "./UserAvatar.vue";
import { replaceDiacritics } from "../libs/helpers.js";

export default {
  name: "AssignExpertModal",
  props: ["users", "question-id"],
  components: { UserAvatar },

  data: function() {
    return {
      filter: {
        value: "",
      },
    };
  },

  filters: {
    // Pluralize filter
    pluralize: function(value, term) {
      return value > 1 ? `${term}s` : term;
    },
  },

  methods: {
    // find question assigned to the user
    isQuestionAssigned: function(user) {
      let found = user.questions_assigned.find((q) => {
        return parseInt(q.id) === parseInt(this.questionId);
      });
      return found;
    },

    assignedQuestionsDetails: function(user) {
      let ongoingQuestionsCount = this.ongoingQuestionsCount(user);
      let pendingQuestionsCount = this.pendingQuestionsCount(user);

      let msg = [];
      if (ongoingQuestionsCount)
        msg.push(`${ongoingQuestionsCount} avec réponse`);
      if (pendingQuestionsCount)
        msg.push(`${pendingQuestionsCount} en attente`);

      return msg.join(", ");
    },

    // Ongoing question count for the user
    ongoingQuestionsCount: function(user) {
      let ongoingQuestions = user.questions_assigned.filter(
        (q) => q.status === "ongoing"
      );
      return ongoingQuestions.length;
    },

    // Pending question count for the user
    pendingQuestionsCount: function(user) {
      let pendingQuestions = user.questions_assigned.filter(
        (q) => q.status === "pending"
      );
      return pendingQuestions.length;
    },

    closeModal: function() {
      this.$emit("modalClose");
    },

    // Emit event to parents
    handleClick: function(user) {
      this.$emit("click", user);
    },
  },

  computed: {
    // Is the expert assigned to the question?
    isExpertAssigned: function() {},

    // Filter experts
    filteredList: function() {
      let searched = this.filter.value.toLowerCase();
      return this.users.filter((user) => {
        let searched = replaceDiacritics(this.filter.value.toLowerCase());
        return (
          replaceDiacritics(user.firstname.toLowerCase()).includes(searched) ||
          replaceDiacritics(user.lastname.toLowerCase()).includes(searched) ||
          replaceDiacritics(user.specialty.toLowerCase()).includes(searched)
        );
      });
    },
  },
};
</script>
